import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 612.000000 428.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,596.000000) scale(0.100000,-0.100000)">
          <path d="M0 2980 l0 -2980 4195 0 4195 0 0 2980 0 2980 -4195 0 -4195 0 0
-2980z m2450 1541 c0 -33 -4 -40 -25 -45 l-25 -6 0 -184 c0 -106 5 -196 11
-214 19 -56 81 -54 105 4 10 24 14 80 14 203 0 93 -3 176 -6 185 -3 9 -15 16
-25 16 -15 0 -19 7 -19 40 l0 40 85 0 85 0 0 -39 c0 -33 -4 -40 -22 -43 -23
-3 -23 -4 -28 -218 -5 -234 -11 -261 -67 -297 -37 -24 -149 -25 -184 -1 -55
35 -64 74 -69 303 -5 208 -5 210 -27 213 -19 3 -23 10 -23 43 l0 39 110 0 110
0 0 -39z m1031 16 c35 -18 50 -35 74 -82 29 -58 30 -64 30 -200 0 -127 -2
-145 -24 -188 -12 -26 -39 -60 -59 -75 -34 -26 -40 -27 -184 -30 l-148 -3 0
40 c0 37 2 41 25 41 l25 0 0 215 c0 214 0 216 -23 224 -17 7 -22 16 -20 42 1
19 2 35 2 37 1 1 59 2 129 2 115 0 134 -3 173 -23z m1592 -11 c62 -62 63 -202
2 -263 -31 -31 -37 -33 -104 -33 l-71 0 0 -79 c0 -92 5 -111 31 -111 15 0 19
-7 19 -40 l0 -40 -75 0 -75 0 0 -50 c0 -28 7 -67 16 -88 11 -27 14 -54 9 -102
-7 -69 13 -146 42 -163 7 -4 13 -23 13 -42 0 -18 5 -35 10 -37 6 -2 13 -28 16
-58 3 -30 7 -66 10 -79 5 -22 6 -23 24 -6 18 16 20 17 20 2 0 -10 5 -17 11
-17 20 0 79 -69 73 -85 -5 -12 3 -15 35 -15 39 0 41 -2 41 -30 0 -24 4 -30 21
-30 19 0 21 -5 17 -45 -2 -26 1 -45 7 -45 5 0 1 -6 -10 -13 -19 -11 -18 -12
15 -19 19 -4 -21 -5 -90 -3 -212 6 -411 75 -615 211 -99 67 -260 224 -331 325
l-21 30 -57 -70 c-70 -88 -174 -194 -246 -253 -49 -39 -74 -57 -155 -105 -64
-39 -207 -103 -227 -103 -7 1 2 7 20 15 17 8 32 19 32 25 0 14 -13 13 -28 -2
-7 -7 -12 -8 -12 -2 0 6 15 18 33 27 l32 17 -35 -6 c-26 -4 -31 -3 -20 5 8 6
11 11 7 11 -5 0 6 18 23 40 21 27 40 40 56 40 21 0 24 4 24 40 0 22 5 40 10
40 6 0 10 33 10 80 0 47 4 80 10 80 6 0 10 10 10 22 0 12 7 30 17 40 15 17 15
18 0 18 -10 0 -17 5 -17 10 0 6 17 10 38 10 29 0 39 5 47 23 6 12 19 26 30 31
30 13 45 52 45 113 0 42 3 53 16 53 13 0 15 10 12 53 l-3 52 50 -2 50 -3 -40
14 c-22 8 -34 14 -26 15 11 1 10 5 -4 21 -21 24 -40 26 -31 5 3 -10 1 -15 -7
-13 -6 1 -22 -1 -34 -6 -32 -10 -101 11 -114 36 -6 12 -12 50 -12 85 -1 75 19
102 94 129 41 15 51 23 59 52 11 41 -5 82 -32 82 -16 0 -17 -3 -8 -21 7 -12 9
-37 5 -59 -5 -31 -12 -41 -35 -49 -45 -15 -70 11 -72 75 -1 88 37 124 132 124
108 0 132 -30 134 -165 0 -52 4 -95 9 -95 4 0 7 16 7 35 0 19 4 35 9 35 5 0 7
-9 4 -20 -4 -13 0 -20 10 -20 17 0 27 -29 27 -80 0 -19 4 -40 8 -47 4 -7 9 50
10 127 2 118 0 139 -12 134 -10 -4 -16 2 -18 18 -5 39 3 78 18 78 22 0 44 43
51 98 5 47 7 50 37 54 18 2 43 1 57 -2 21 -5 22 -7 6 -13 -16 -6 -18 -16 -15
-69 l3 -63 35 -5 c34 -5 35 -6 33 -45 -1 -22 -2 -43 -2 -47 -1 -4 -7 -5 -15
-2 -8 3 -24 0 -35 -6 -20 -10 -21 -17 -17 -93 3 -45 6 -102 6 -126 0 -64 12
-43 14 26 2 57 2 58 31 58 l30 0 -1 -75 c0 -41 -4 -86 -9 -100 -8 -23 -7 -24
8 -11 9 7 17 10 17 5 0 -5 14 -24 31 -41 23 -25 38 -33 62 -31 l32 1 -32 4
c-19 2 -33 10 -33 17 0 8 -8 16 -18 18 -19 5 -42 66 -42 109 0 57 20 99 58
119 35 18 36 19 11 20 -38 0 -52 23 -53 86 0 51 3 59 31 84 59 53 173 47 214
-10 15 -21 19 -47 20 -152 1 -74 6 -128 12 -128 5 0 7 13 5 30 -3 18 0 31 9
34 18 7 40 -2 40 -17 4 -61 8 -77 18 -65 5 7 25 15 43 16 24 3 32 0 32 -13 0
-13 9 -16 48 -14 86 4 82 -7 82 225 0 199 -1 205 -21 212 -16 6 -22 18 -24 45
l-2 37 158 0 158 0 34 -34z m197 -210 c0 -134 3 -251 6 -260 3 -9 12 -16 20
-16 10 0 14 -12 14 -40 l0 -40 -95 0 -95 0 0 40 c0 28 4 40 14 40 23 0 26 24
26 232 0 191 -1 200 -20 205 -16 4 -20 14 -20 44 l0 39 75 0 75 0 0 -244z
m-2360 168 c64 -55 87 -155 52 -235 -21 -49 -46 -71 -129 -113 -58 -29 -79
-50 -33 -33 23 9 38 8 63 -1 49 -19 50 -19 60 16 8 26 15 32 38 32 28 0 29 -2
29 -47 0 -147 -63 -196 -174 -137 -45 24 -51 25 -61 10 -7 -9 -26 -16 -45 -16
l-33 0 6 55 c8 74 46 148 112 216 46 48 56 65 61 103 6 40 4 50 -16 71 -27 28
-52 32 -70 10 -10 -11 -9 -18 4 -30 21 -21 21 -94 0 -123 -21 -30 -58 -28 -79
5 -21 31 -16 112 9 165 38 78 143 104 206 52z m1247 -80 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m1738 -26 c19 -9 28 -9 37 0 13 13 57 16 74
4 47 -31 43 -142 -5 -142 -30 0 -44 26 -37 70 5 33 4 38 -9 33 -13 -4 -14 -14
-8 -52 17 -109 0 -178 -54 -221 -22 -17 -38 -21 -75 -18 -29 2 -47 -1 -45 -7
2 -5 42 -11 89 -13 76 -2 88 -5 109 -28 20 -21 24 -36 24 -88 0 -106 -46 -146
-166 -146 -124 0 -173 60 -127 156 7 15 7 28 -1 42 -17 32 -13 87 8 114 18 23
19 28 6 64 -31 86 -7 190 52 226 33 20 91 23 128 6z m-433 -146 c-3 -157 5
-206 35 -200 24 4 31 38 32 145 1 99 -4 123 -25 123 -10 0 -14 12 -14 40 l0
40 75 0 75 0 0 -154 c0 -162 3 -186 26 -186 10 0 14 -12 14 -40 l0 -40 -75 0
c-59 0 -75 3 -75 15 0 10 -8 6 -25 -10 -17 -17 -35 -25 -60 -25 -80 0 -95 34
-95 217 0 112 -2 123 -20 133 -15 8 -20 21 -20 50 l0 40 78 0 77 0 -3 -148z
m-1264 27 c-3 -8 -7 -3 -11 10 -4 17 -3 21 5 13 5 -5 8 -16 6 -23z m-491 -345
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m313 -1488 l0 -605 -37
-30 c-21 -17 -67 -41 -103 -53 -55 -20 -86 -23 -205 -23 -104 0 -154 4 -195
17 -68 21 -135 64 -149 95 -7 16 -11 151 -11 401 l0 377 49 65 c69 91 144 160
176 160 l25 0 0 -460 0 -461 98 3 97 3 0 529 0 529 31 13 c38 15 159 42 197
43 l27 1 0 -604z m1457 422 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-707 -468 l0 -360 -225 0 -225 0 0 -105 0 -104 225 6 225 6 0 -130 0
-130 -342 -6 c-187 -3 -345 -4 -350 -1 -4 3 -8 167 -8 365 l0 359 225 0 225 0
0 100 0 100 -225 0 -225 0 0 130 0 130 350 0 350 0 0 -360z m-1660 -760 c4 -3
15 -21 24 -40 9 -20 60 -82 113 -139 66 -70 93 -105 85 -113 -7 -7 -17 -6 -33
3 -22 11 -23 11 -10 -4 11 -15 10 -18 -14 -27 -46 -18 -86 -11 -152 26 -66 37
-83 60 -83 114 0 16 -4 32 -10 35 -16 10 -12 85 4 85 10 0 12 7 9 23 -6 22 -6
22 22 -3 l28 -25 -17 26 c-18 29 -11 45 11 24 8 -8 20 -15 26 -15 7 0 4 5 -5
11 -10 5 -18 16 -18 23 0 16 -31 16 -42 1 -4 -5 -6 10 -3 35 2 25 0 52 -5 60
-6 8 -10 11 -10 5 0 -5 -9 8 -19 30 -14 28 -20 34 -20 20 -1 -12 4 -26 11 -33
6 -6 9 -15 5 -18 -12 -12 -20 5 -23 49 -2 23 -6 53 -10 67 -3 14 24 -29 61
-95 37 -66 70 -122 75 -125z m2106 46 c-14 -19 -24 -41 -23 -48 2 -7 2 -10 0
-5 -3 4 -14 -3 -25 -15 l-20 -23 17 30 c29 48 70 104 74 99 2 -2 -8 -19 -23
-38z m-368 -456 c89 1 282 -25 292 -40 4 -6 291 -19 455 -21 45 -1 137 -28
130 -39 -4 -6 -15 -10 -26 -10 -10 0 -19 -6 -19 -14 0 -10 -20 -16 -67 -21
-37 -4 -76 -10 -87 -14 -17 -7 -14 -9 15 -15 32 -7 33 -8 15 -21 -11 -8 -27
-15 -37 -15 -9 0 -22 -6 -28 -14 -7 -8 -26 -18 -42 -21 -17 -4 -35 -15 -42
-26 -6 -10 -18 -19 -25 -19 -7 0 -23 -9 -35 -20 -12 -12 -33 -23 -47 -27 -14
-3 -36 -15 -50 -27 -14 -13 -32 -22 -40 -21 -8 0 -38 -12 -66 -27 -28 -15 -60
-28 -72 -28 -12 0 -22 -4 -22 -9 0 -11 -56 -41 -77 -41 -7 0 -13 -5 -13 -10 0
-6 -3 -9 -7 -9 -21 3 -44 -2 -39 -9 2 -4 -12 -9 -32 -10 -20 -2 -41 -7 -46
-12 -6 -6 -21 -10 -34 -10 -13 0 -33 -5 -45 -12 -21 -12 -31 -14 -76 -17 -11
0 -26 -7 -34 -15 -8 -8 -42 -16 -78 -19 -35 -2 -84 -10 -109 -16 -49 -13 -95
-18 -235 -26 -248 -13 -305 -15 -305 -7 0 4 -19 8 -42 8 -61 2 -180 14 -198
21 -26 10 -43 13 -96 17 -28 2 -61 9 -72 15 -11 6 -34 13 -51 14 -16 2 -36 8
-43 14 -7 6 -38 13 -68 15 -68 5 -78 8 -103 31 -11 11 -27 17 -39 14 -11 -3
-34 5 -55 19 -20 13 -41 24 -47 24 -17 0 -66 24 -66 32 0 3 -17 12 -37 19 -21
7 -44 20 -51 31 -7 10 -20 18 -28 18 -15 0 -44 23 -44 34 0 3 -9 15 -20 26
-14 14 -20 33 -20 63 0 51 -9 66 -41 72 -13 3 -31 15 -41 27 -16 22 -16 23 4
31 11 4 80 14 152 23 72 8 151 22 176 30 25 8 84 14 133 14 48 0 87 4 87 8 0
4 17 9 38 10 20 1 51 5 67 9 17 4 39 5 49 4 14 -2 17 2 13 13 -4 9 -4 16 -1
16 3 0 63 -27 133 -59 70 -33 165 -71 212 -84 294 -86 611 -47 896 109 38 20
70 42 73 48 2 6 6 3 8 -7 3 -14 13 -18 50 -17z m-1648 -26 c0 -8 -4 -12 -10
-9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z"/>
<path d="M3350 4265 l0 -215 25 0 c36 0 56 38 67 131 15 112 -1 241 -33 275
-12 13 -31 24 -41 24 -17 0 -18 -14 -18 -215z"/>
<path d="M4904 4467 c-3 -8 -4 -45 -2 -83 3 -67 4 -69 28 -66 36 5 60 38 60
82 0 39 -18 62 -58 75 -15 4 -24 2 -28 -8z"/>
<path d="M4310 4310 c0 -5 4 -10 8 -10 5 0 5 -24 1 -52 -3 -29 -5 -55 -3 -57
2 -2 13 4 24 14 23 20 28 83 8 103 -15 15 -38 16 -38 2z"/>
<path d="M3753 4096 c-13 -15 -19 -34 -16 -47 8 -28 26 -18 36 20 12 47 4 58
-20 27z"/>
<path d="M3902 4095 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4336 4089 c-17 -29 -17 -32 -1 -47 17 -16 17 -16 12 6 -4 18 -2 22
9 18 10 -4 14 3 14 24 0 38 -11 38 -34 -1z"/>
<path d="M3950 3994 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M3971 3966 c-14 -17 -7 -36 14 -36 7 0 17 -9 20 -20 6 -20 35 -28 35
-10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 13 16 13 40 0 12 -6 27 -6 42 -1 22
9 21 10 -8 10 -17 1 -43 10 -58 22 -14 11 -26 17 -26 12 0 -4 -4 -2 -9 5 -6
10 -10 10 -20 -2z"/>
<path d="M4362 3950 c-8 -13 -8 -20 -2 -20 6 0 13 7 17 15 9 24 -1 27 -15 5z"/>
<path d="M4135 3940 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z"/>
<path d="M3848 3923 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4283 3923 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4443 3923 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4098 3903 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3798 3883 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5063 3043 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M5822 4318 c-14 -14 -16 -176 -3 -197 20 -31 31 3 31 92 0 98 -8 125
-28 105z"/>
<path d="M5793 3890 c-26 -11 -29 -23 -9 -39 39 -32 136 -19 136 18 0 29 -76
41 -127 21z"/>
<path d="M3889 2981 c-37 -10 -74 -21 -83 -24 -14 -6 -16 -60 -16 -542 l0
-535 -125 0 -125 0 -2 463 -3 462 -67 -68 c-36 -38 -79 -87 -95 -110 l-28 -41
4 -365 c2 -201 7 -374 13 -384 29 -55 169 -99 317 -98 134 1 233 29 289 81
l22 21 0 579 c0 546 -1 580 -17 579 -10 0 -48 -8 -84 -18z"/>
<path d="M4100 2610 l0 -100 220 -2 220 -3 5 -127 5 -128 -225 0 -225 0 0
-335 0 -335 259 0 c142 0 286 3 320 6 l61 7 0 99 0 99 -212 -1 c-117 0 -219
-1 -226 0 -10 0 -12 29 -10 128 l3 127 223 3 222 2 0 330 0 330 -320 0 -320 0
0 -100z"/>
<path d="M3050 1666 c0 -8 7 -16 15 -20 13 -4 14 -2 5 14 -13 23 -20 26 -20 6z"/>
<path d="M3306 1345 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
